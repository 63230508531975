/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// LOADING - START

+generateKeyFrames(animationSpin)
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)

img#imageLoadingCircle
	+useAnimation(animationSpin, 1, 0, infinite)
	+setAnimationTimingFunction(linear)

// LOADING - END


// NAVIGATION - START

main.MainShow
	+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
	+setTransition(all, 0.5, ease-in)
main.MainHide
	+setSlideTransform(-30, 120px, -100px, 0.6, 0.6, 0.6)
	+setTransition(all, 0.5, ease-out)

+generateKeyFrames(animationNavigationShow)
	0%
		margin-left: $SPACE_NAVIGATION_HIDE
		+setOpacity(0.0)
	100%
		margin-left: $SPACE_NAVIGATION_SHOW
		+setOpacity(1.0)

+generateKeyFrames(animationNavigationHide)
	0%
		margin-left: $SPACE_NAVIGATION_SHOW
		+setOpacity(1.0)
	100%
		margin-left: $SPACE_NAVIGATION_HIDE
		+setOpacity(0.0)

nav.NavShow
	+useAnimation(animationNavigationShow, 0.4, 0, 1)
	+setAnimationTimingFunction(ease-in)

nav.NavHide
	+useAnimation(animationNavigationHide, 0.4, 0, 1)
	+setAnimationTimingFunction(ease-out)

+generateKeyFrames(animationNavigationItemShow)
	0%
		margin-left: $SPACE_NAVIGATIONITEM_HIDE
		+setOpacity(0.0)
	100%
		margin-left: $SPACE_NAVIGATIONITEM_SHOW
		+setOpacity(1.0)

+generateKeyFrames(animationNavigationItemHide)
	0%
		margin-left: $SPACE_NAVIGATIONITEM_SHOW
	100%
		margin-left: $SPACE_NAVIGATIONITEM_HIDE
		+setOpacity(0.0)

+generateKeyFrames(animationCheckShow)
	0%
		height: 0px
	100%
		height: auto

+generateKeyFrames(animationCheckHide)
	0%
		height: auto
	100%
		height: 0px

nav
	input[type=checkbox]
		display: none

nav.NavNone
	display: none

nav.NavHide
	ul.ListNavigation
		li.ListItemNavigation
			input[type=checkbox].CheckBoxNavigationTrigger:checked + ul.ListSubNavigation
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].CheckBoxNavigationTrigger + ul.ListSubNavigation
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium:checked + ul.ListSubNavigationCalculatePremium
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium + ul.ListSubNavigationCalculatePremium
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)

nav.NavShow
	ul.ListNavigation
		li.ListItemNavigation
			input[type=checkbox].CheckBoxNavigationTrigger:checked + ul.ListSubNavigation
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].CheckBoxNavigationTrigger + ul.ListSubNavigation
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium:checked + ul.ListSubNavigationCalculatePremium
				display: block
				+setAnimationTimingFunction(ease-out)
				+useAnimation(animationCheckShow, 0.4, 0, 1)
			input[type=checkbox].checkBoxNavigationTriggerCalculatePremium + ul.ListSubNavigationCalculatePremium
				display: none
				+setAnimationTimingFunction(ease-in)
				+useAnimation(animationCheckHide, 0.4, 0, 1)

ul.ListNavigation
	li.ListItemNavigation, li.ListItemSubNavigation
		a, label, span
			&:hover
				transition: padding-left .4s
				+setAnimationTimingFunction(ease-out)

// NAVIGATION - END


// SIGN - START

// SIGN -END